import { gql } from "@apollo/client"

export const productDetailsMobileFragment = {
  entry: gql`
    fragment ProductDetailsMobileFragment on Product {
      id
      name
      h1Tag
      description
      recommendationLevel
      lastPurchasedDate
      noOfTimesPurchased
      ratingsCount
      attributes {
        attribute {
          id
          name
        }
        value {
          id
          name
          value: name
        }
      }
      brand {
        id
        name
        logo {
          url
        }
      }
      category {
        id
        name
        description
      }
      variants {
        id
        name
        expiryDate: displayExpiryDate(format: "%b %-Y") {
          value
        }
        pricing {
          price {
            gross {
              amount
            }
          }
        }
      }
    }
  `,
}
