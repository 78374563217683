import { gql } from "@apollo/client"

export const ProductRatingFragment = {
  productEntry: gql`
    fragment ProductRating on Product {
      id
      name
      recommendationLevel
      lastPurchasedDate
      noOfTimesPurchased
      ratingsCount
    }
  `,
}
