import { gql } from "@apollo/client"
import { BreadcrumbsFragments } from "@temp/@next/components/molecules/ProductPageBreadcrumbs/queries"
import { AuthenticityCardContentsFragments } from "@temp/@next/components/organisms/AuthenticityCardContents/queries"
import { BottomFloaterFragments } from "@temp/@next/components/organisms/BottomFloater/queries"
import { productDetailsMobileFragment } from "@temp/@next/components/organisms/ProductDetailsMobile/queries"
import { ImageSliderFragments } from "@temp/@next/components/organisms/ProductImageSlider/queries"
import { SeoFragments } from "@temp/@next/components/organisms/ProductMetaWrapper/queries"
import { MobilePriceAndQuantityFragments } from "@temp/@next/components/organisms/ProductPriceAndQuantity/queries"
import { ProductVariantSelectorFragments } from "@temp/@next/components/organisms/ProductVariantSelector/queries"
import { DesktopMiddleColFragment } from "@temp/@next/components/organisms/ProductPageDesktopMiddleCol/queries"
import { SimilarProductsFragments } from "@temp/@next/components/organisms/SimilarProducts/queries"

import {
  comboProductCardFragment,
  productCardFragment,
} from "@temp/@next/components/organisms/ProductCard/queries"
import { ProductRatingFragment } from "@temp/@next/components/organisms/ProductRatingCard/queries"
import { ProductMediaFragment } from "@temp/@next/components/organisms/ProductReviews/queries"

export const basicProductFragment = gql`
  fragment BasicProductFields on Product {
    id
    name
    h1Tag
    stockQuantity
    neStockQuantity
    recommendationLevel
    lastPurchasedDate
    noOfTimesPurchased
    ratingsCount
    superSaver
    badge {
      id
      name
      color
    }
    productType {
      id
      name
      hasVariants
    }
    firstImage {
      id
      url
      alt
    }
    brand {
      id
      name
      h1Tag
      seoTitle
      seoDescription
      logo {
        url
      }
    }
  }
`

const productVariantFragment = gql`
  fragment ProductVariantFields on ProductVariant {
    id
    sku
    gtin
    name
    stockQuantity
    neStockQuantity
    isAvailable
    purchaseCount
    displayExpiryDate(format: "%b'%-y") {
      value
    }
    neDisplayExpiryDate(format: "%b'%-y") {
      value
    }
    expiryDate: displayExpiryDate(format: "%b %-Y") {
      value
    }
    mfgDate: displayMfgDate(format: "%b %-Y") {
      value
      __typename
    }
    seoExpiryDate: displayExpiryDate(format: "%Y-%m-%d") {
      value
    }
    seoNEExpiryDate: neDisplayExpiryDate(format: "%Y-%m-%d") {
      value
    }
    isNearExpiry
    nearExpiryPrice {
      amount
    }
    pricing {
      nePrice {
        gross {
          amount
        }
      }
      price {
        gross {
          amount
          localized
        }
        currency
      }
      priceUndiscounted {
        gross {
          amount
          localized
        }
        currency
      }
      listPrice {
        gross {
          amount
          localized
        }
        currency
      }
      discount {
        gross {
          amount
          localized
        }
        currency
      }
    }
    attributes {
      attribute {
        id
        name
      }
      value {
        id
        name
        value: name
      }
    }
    images {
      id
      url
    }
  }
`

export const productDetailsQuery = gql`
  query ProductDetails($id: ID!) {
    product(id: $id) {
      ...BasicProductFields
      ...ProductSeo
      ...ProductPageBreadcrumbsFragment
      ...ProductImagesFragment
      ...ProductDetailsMobileFragment
      ...ProductVariantSelectorFragment
      ...ProductPriceAndQuantityFragment
      ...AuthenticityCardContentsFragment
      ...ProductDesktopMiddleColFragment
      ...SimilarProductsFragment
      ...ProductCardFragment
      ...ProductRating
      ...ProductMedia
      url
      sku
      review {
        id
        rating
        bestRating
        aggregateRating
        author
        count
      }
      faqs {
        id
        question
        answer
      }
      variants {
        ...ProductAddToCartFragment
      }
    }
  }
  ${basicProductFragment}
  ${SeoFragments.product}
  ${BreadcrumbsFragments.productPage}
  ${ImageSliderFragments.productImages}
  ${productDetailsMobileFragment.entry}
  ${ProductVariantSelectorFragments.entry}
  ${MobilePriceAndQuantityFragments.product}
  ${AuthenticityCardContentsFragments.entry}
  ${SimilarProductsFragments.product}
  ${BottomFloaterFragments.productEntry}
  ${DesktopMiddleColFragment.product}
  ${productCardFragment}
  ${ProductRatingFragment.productEntry}
  ${ProductMediaFragment.productEntry}
`

// FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.
export const productVariantsQuery = gql`
  ${basicProductFragment}
  ${productVariantFragment}
  query VariantList($ids: [ID!]) {
    productVariants(ids: $ids, first: 100) {
      edges {
        node {
          ...ProductVariantFields
          stockQuantity
          neStockQuantity
          product {
            ...BasicProductFields
          }
        }
      }
    }
  }
`

export const similarProducts = gql`
  query SimilarProductsCategory($id: ID!) {
    purchasables(categories: [$id], first: 9) {
      edges {
        node {
          id
          product {
            ...ProductCardFragment
          }
          comboProduct {
            ...ComboProductCardFragment
          }
        }
      }
    }
  }
  ${productCardFragment}
  ${comboProductCardFragment}
`

export const getProductReviews = gql`
  query ProductReviews($productIds: [ID], $first: Int) {
    productReviews(productIds: $productIds, first: $first) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
          }
          review
          recommends
          upvoteCount
          downvoteCount
          status
          updated
          created
        }
      }
    }
  }
`
