import { gql } from "@apollo/client"

export const AuthenticityCardContentsFragments = {
  entry: gql`
    fragment AuthenticityCardContentsFragment on Product {
      id
      name
      brand {
        id
        name
        importers(first: 10) {
          edges {
            node {
              id
              name
              description
              stickers {
                image
              }
              certificates {
                image
              }
            }
          }
        }
      }
    }
  `,
}
