import { gql } from "@apollo/client"

export const getProductReviews = gql`
  query ProductReviewsQuery(
    $productIds: [ID]
    $first: Int
    $sortOrder: ProductReviewSortOrder
    $after: String
    $before: String
  ) {
    productReviews(
      productIds: $productIds
      first: $first
      sortOrder: $sortOrder
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
          }
          review
          recommends
          upvoteCount
          downvoteCount
          status
          updated
          created
          unboxingMedias(first: 6) {
            totalCount
            edges {
              node {
                id
                mediaType
                mediaUri
                productReview {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                }
                thumbnailUrl
                created
              }
            }
          }
        }
      }
    }
  }
`

export const getUserProductReview = gql`
  query UserProductReviewQuery($productIds: [ID]) {
    me {
      id
      productReviews(productIds: $productIds, first: 2) {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
            review
            recommends
            upvoteCount
            downvoteCount
            unboxingMedias(first: 6) {
              totalCount
              edges {
                node {
                  id
                  mediaType
                  mediaUri
                  productReview {
                    id
                    user {
                      id
                      firstName
                      lastName
                    }
                  }
                  thumbnailUrl
                  created
                }
              }
            }
            status
            updated
            created
          }
        }
      }
      productReviewVotes(productIds: $productIds, first: 10) {
        edges {
          node {
            id
            productReview {
              id
              review
            }
            vote
          }
        }
      }
    }
  }
`

export const ProductMediaFragment = {
  productEntry: gql`
    fragment ProductMedia on Product {
      featuredUnboxingMedias(first: 6) {
        edges {
          node {
            id
            mediaType
            mediaUri
            productReview {
              id
              user {
                id
                firstName
                lastName
              }
            }
            thumbnailUrl
            created
          }
        }
      }
      unboxingMedias(first: 9) {
        edges {
          node {
            id
            mediaType
            mediaUri
            productReview {
              id
              user {
                id
                firstName
                lastName
              }
            }
            thumbnailUrl
            created
          }
        }
      }
    }
  `,
}
export const getProductMedia = gql`
  query ProductMediaQuery($id: ID!, $first: Int, $after: String) {
    product(id: $id) {
      featuredUnboxingMedias(first: 6) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            mediaType
            mediaUri
            productReview {
              id
              user {
                id
                firstName
                lastName
              }
            }
            thumbnailUrl
            created
          }
        }
      }
      unboxingMedias(first: $first, after: $after) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            id
            mediaType
            mediaUri
            productReview {
              id
              user {
                id
                firstName
                lastName
              }
            }
            thumbnailUrl
            created
          }
        }
      }
    }
  }
`
