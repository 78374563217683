import { gql } from "@apollo/client"

export const ProductVariantSelectorFragments = {
  entry: gql`
    fragment ProductVariantSelectorFragment on Product {
      id
      name
      category {
        id
        name
      }
      productType {
        id
        name
      }
      variants {
        id
        name
        stockQuantity
        neStockQuantity
        attributes {
          attribute {
            id
            name
          }
          value {
            id
            name
            value: name
          }
        }
        displayExpiryDate(format: "%b'%-y") {
          value
        }
        neDisplayExpiryDate(format: "%b'%-y") {
          value
        }
        expiryDate: displayExpiryDate(format: "%b %-Y") {
          value
        }
        mfgDate: displayMfgDate(format: "%b %-Y") {
          value
        }
        pricing {
          nePrice {
            gross {
              amount
            }
          }
          price {
            gross {
              amount
            }
          }
          listPrice {
            gross {
              amount
            }
          }
        }
      }
    }
  `,
}
