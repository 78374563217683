import { gql } from "@apollo/client"

export const DesktopMiddleColFragment = {
  product: gql`
    fragment ProductDesktopMiddleColFragment on Product {
      id
      name
      h1Tag
      description
      recommendationLevel
      lastPurchasedDate
      noOfTimesPurchased
      ratingsCount
      attributes {
        attribute {
          id
          name
        }
        value {
          id
          name
          value: name
        }
      }
      brand {
        id
        name
        logo {
          url
        }
      }
      category {
        id
        name
        description
      }
      variants {
        id
        name
        expiryDate: displayExpiryDate(format: "%b %-Y") {
          value
        }
        pricing {
          price {
            gross {
              amount
            }
          }
        }
      }
    }
  `,
  comboProduct: gql`
    fragment ComboProductDesktopMiddleColFragment on ComboProduct {
      id
      name
      brand {
        id
        name
      }
      category {
        id
        name
      }
      products {
        id
        name
        description
        brand {
          id
          name
        }
        attributes {
          attribute {
            id
            name
          }
          value {
            id
            name
          }
        }
        productType {
          id
          name
        }
        category {
          id
          name
          description
          ancestors(last: 5) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
      variations {
        id
        stockQuantity
        pricing {
          price {
            gross {
              amount
            }
          }
          listPrice {
            gross {
              amount
            }
          }
        }
        variants {
          id
          name
        }
      }
    }
  `,
}
