import { gql } from "@apollo/client"

export const BottomFloaterFragments = {
  productEntry: gql`
    fragment ProductAddToCartFragment on ProductVariant {
      id
      name
      isNearExpiry
      stockQuantity
      neStockQuantity
      expiryDate: displayExpiryDate(format: "%b %-Y") {
        value
      }
      nearExpiryPrice {
        amount
      }
      pricing {
        nePrice {
          gross {
            amount
          }
        }
        price {
          gross {
            amount
          }
        }
        listPrice {
          gross {
            amount
          }
        }
      }
    }
  `,

  productDataEntry: gql`
    fragment ProductDataFragment on Product {
      id
      name
      brand {
        id
        name
      }
      category {
        id
        name
      }
    }
  `,
  comboProductDataEntry: gql`
    fragment ComboProductDataFragment on ComboProduct {
      id
      name
      brand {
        id
        name
      }
      category {
        id
        name
      }
    }
  `,

  comboProductEntry: gql`
    fragment ComboProductAddToCartFragment on ComboProductVariation {
      id
      stockQuantity
      pricing {
        price {
          gross {
            amount
          }
        }
        listPrice {
          gross {
            amount
          }
        }
      }
      variants {
        id
        name
      }
    }
  `,
}
